.MultiSearchBar {
  background-color: white;
  min-height: 35px;
  width: 100%;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 5px;

  display: flex;
  flex: row;
  flex-wrap: wrap;
  position: relative;
}

.MultiSearchBar.focus {
  box-shadow: 0 0 2px rgb(0, 0, 0);
}

.MultiSearchBar-input {
  width: 100%;
  min-height: 20px;
  padding: 0;
  border-width: 0px;
  background-color: transparent;
  font-size: 17px;
  font-family: 'Josefin Sans', sans-serif;
  padding: 5px;
}

.MultiSearchBar-input::placeholder {
  font-style: italic;
  font-size: 15px;
}

.MultiSearchBar-input:focus {
  outline: none;
}

.MultiSearchBar-loading-overlay {
  background-color: rgba(0, 0, 0, 0.55);
  color: white;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 1;
  visibility: visible;
  transition: visibility 150ms, opacity 150ms linear;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.MultiSearchBar-loading-overlay.hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 150ms, opacity 150ms linear;
}

.MultiSearchBar-loading-text {
  margin-top: 5px;
  font-size: 0.8em;
  font-family: 'Josefin Sans', sans-serif;
}

.MultiSearchBar-loading-icon {
  color: white;
  font-size: 25px;
  -webkit-animation: fa-spin 0.5s infinite linear;
  animation: fa-spin 0.5s infinite linear;
}
