.LeftSidebarContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px;
  text-align: center;
  padding-top: 60px;
}

.LeftSidebarContent-title {
  font-size: 1.5em;
  margin-bottom: 30px;
}

.LeftSidebarContent-toggle-switch {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.LeftSidebarContent-toggle-switch.bottom {
  margin-bottom: 60px;
}

.LeftSidebarContent-toggle-switch span {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1.1em;
}

.LeftSidebarContent-toggle-switch .no-right-margin {
  margin-right: 0px;
}

.LeftSidebarContent-about-text {
  text-align: left;
}

.LeftSidebarContent-toggle-switch.disabled {
  color: gray;
}

.LeftSidebarContent-credits {
  margin-top: auto;
  font-size: 0.8em;
}