.ChartView {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 100px;
}

.ChartView-Container {
  margin-top: 100px;
}

.ChartView-Container > span {
  font-size: 1.5em;
}

.ChartView-Chart {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.CharView-LabelList {
  font-size: 0.7em;
  text-anchor: start;
}
