.ArticlePane {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    flex: 1;
    max-height: 500px;
}

.ArticlePane > span {
    font-size: 1.5em;
    margin-bottom: 12px;
}

.ArticlePane-total { 
    font-size: 0.8em;
    margin-bottom: 5px;
}

.ArticlePane-list {
    border-width: 1px;
    border-color: black;
    border-style: solid;
    border-radius: 5px;
}

.ArticlePane-listitem {
    background-color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    padding-left: 5px;
}

.ArticlePane-listitem:hover {
    background-color: rgb(245, 245, 245);
}

.ArticlePane-listitem > a:link {
    color: black;
    font-size: 0.8em;
    text-decoration: none;
}

.ArticlePane-listitem > a:visited {
    color: gray;
}

.ArticlePane-listitem-pill-container {
    display: flex;
    flex-direction: row;
}

.ArticlePane-listitem-pill {
    font-size: 0.6em;
    background-color: black;
    color: white;
    padding: 3px;
    border-radius: 5px;
    margin-top: 2px;
    margin-right: 5px;
}
