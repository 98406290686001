.MapView {
  text-align: center;
  height: 100vh;
}

.leaflet-container {
  height: 100%;
  z-index: 1;
  background-color: rgb(208, 208, 208);
}

.MapView-toggle-heatmap-container {
  position: absolute;
  top: calc(100% - 50px);
  z-index: 2;
  width: 150px;
  height: 50px;
  background-color: black;
}

.MapView-heatmap-layer {
  display: hidden;
}

.MapView-marker-popup {
  font-family: 'Josefin Sans', sans-serif;
}

.MapView-MarkerPopup-Pill {
  display: inline-block;
  background-color: black;
  color: white;
  padding: 1px 5px 1px 5px;
  border-radius: 5px;
  margin-left: 5px;
  margin-bottom: 2px;
}

.MapView-MarkerPopup-Title > a:link {
  color: black;
}

.MapView-MarkerPopup-Title > a:link:hover {
  color: rgba(0, 0, 0, 0.616);
}

.MapView-MarkerPopup-PillContainer {
  margin-bottom: 5px;
  max-height: 100px;
  overflow-y: scroll;
}
