.Button {
  border-color: black;
  border-width: 1px;
  border-style: solid;
  padding: 5px 10px 5px 10px;
  width: fit-content;
  border-radius: 5px;
  transition: background-color 330ms, color 330ms;
  user-select: none;
}

.Button:hover {
  cursor: pointer;
}

.Button.submit:hover {
  background-color: black;
  color: white;
  transition: background-color 330ms, color 330ms;
}

.Button.submit.disabled {
  border-color: gray;
  background-color: white;
  color: gray;
  transition: background-color 330ms, color 330ms;
}

.Button.toggle:hover {
  box-shadow: 0 0 2px rgb(0, 0, 0);
}

.Button.toggle.selected {
  background-color: black;
  color: white;
  transition: background-color 330ms, color 330ms;
}
