.RightSidebarContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
}

.RightSidebarContent-articles-title {
  font-size: 1.5em;
}
