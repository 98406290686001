.SearchPill {
  background-color: black;
  color: white;
  padding: 3px 5px 3px 5px;
  margin-top: 5px;
  margin-left: 5px;
  border-radius: 5px;
  max-width: 205px;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.SearchPill.large {
  padding: 6px 5px 6px 5px;
}

.SearchPill.focus {
  box-shadow: 0 0 4px rgb(0, 0, 0);
}

.SearchPill-close-button {
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 15px;
  padding-left: 5px;
}

.SearchPill-close-button:hover {
  cursor: pointer;
}
