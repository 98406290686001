.Header {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: black;
  position: fixed;
  align-items: center;
  padding-top: 25px;

  -webkit-transform: none;
  transform: none;
  transition: transform 330ms;

  z-index: 90;
}

.Header.open {
  will-change: transform;
  -webkit-transform: translateX(275px);
  transform: translateX(275px);
  transition: transform 330ms;
}

#Header-logo {
  margin-left: 55px;
  margin-right: 10px;
}

.Header-title-container {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
}

.Header-loading-icon {
  -webkit-animation: fa-spin 0.5s infinite linear;
  animation: fa-spin 0.5s infinite linear;
  visibility: visible;
  opacity: 1;
  transition: visibility 150ms, opacity 150ms linear;
}

.Header-loading-icon.hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 150ms, opacity 150ms linear;
}

.Header-searchTerms {
  align-self: flex-start;
  margin-top: 5px;
  margin-left: 55px;
  font-style: italic;
  visibility: visible;
  opacity: 1;
  transition: visibility 150ms, opacity 150ms linear;

  font-size: 0.9em;
}

.Header-searchTerms.hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 150ms, opacity 150ms linear;
}
