.SearchModule {
  margin-top: 35px;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 25px;
  flex-basis: 110px;
}

.SearchModule-title {
  font-size: 1.5em;
  margin-bottom: 30px;
}

.SearchModule-advance-search-link {
  margin-top: 6px;
  font-size: 0.8em;
  font-style: italic;
  color: gray
}

.SearchModule-advance-search-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.SearchModule-modal {
  background-color: rgba(255, 255, 255, 0.95);
  position: absolute;
  top: 15%;
  bottom: 15%;
  left: 15%;
  right: 15%;
  border-radius: 10px;
  border: 2px;
  border-style: solid;
  padding: 20px;
  outline: none;
  z-index: 200;

  display: flex;
  flex-direction: column;
}

.SearchModule-modal-overlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 199;
}

.SearchModule-modal-close-button {
  text-align: right;
}

.SearchModule-modal-close-div {
  text-align: right;
  font-size: 1.2em;
}

.SearchModule-modal-close-button:hover {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.685)
}

.SearchModule-modal-multisearchbar {
  margin-top: 20px;
  margin-left: 60px;
  margin-right: 60px;
}

.SearchModule-modal-filterby-title {
  margin-left: 60px;
  margin-top: 40px;
  font-size: 1.2em;
}

.SearchModule-modal-filterby-sentiment {
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.SearchModule-modal-filterby-sentiment > span {
  margin-right: 30px;
  width: 70px;
}

.SearchModule-modal-filterby-sentiment > div {
  margin-right: 15px;
}

.SearchModule-modal-filterby-daterange {
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.SearchModule-modal-filterby-daterange > span {
  margin-right: 30px;
  width: 70px;
}

.SearchModule-modal-range-picker {
  padding: 5px 10px 5px 10px;
  outline: none;
  border-width: 1px;
  border-color: black;
  border-radius: 5px;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1em;
  margin-right: 15px;
  width: 100px;
}

.SearchModule-modal-range-picker::placeholder {
  font-style: italic;
  color: gray;
}

.SearchModule-modal-filterby-locations {
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.SearchModule-modal-filterby-locations > span {
  margin-right: 30px;
  width: 70px;
}

.SearchModule-modal-filterby-multiselect {
  width: 60%;
}

.SearchModule-modal-search-options-pills {
  padding: 10px 60px 0px 60px;
  display: flex;
  flex-direction: row;
}

.SearchModule-modal-search-options-pills-container {
  flex-grow: 1;
  overflow-y: scroll;
  max-height: 150px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.SearchModule-modal-search-options-pills-container::-webkit-scrollbar {
  display: none;
}

.SearchModule-modal-search-options-pills-clear {
  cursor: pointer;
  font-style: italic;
  
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 80px;
}

.SearchModule-modal-search-options-pills-clear:hover {
  color: rgba(0, 0, 0, 0.678)
}

.SearchModule-modal-submit-button-container {
  margin-left: 60px;
  margin-right: 60px;
  margin-bottom: 40px;
  flex-grow: 1;
  
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}
