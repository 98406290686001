body {
  margin: 0;
  font-family: 'Josefin Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: rgb(247, 247, 247);
}

body::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

code {
  font-family: 'Josefin Sans', sans-serif;
}
