.MultiSelect {
  width: 70%;
}

.MultiSelect-input {
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  font-size: 1em;
  font-family: 'Josefin Sans', sans-serif;
  width: 100%;
  max-width: 300px;
}

.MultiSelect-input:focus {
  outline: none;
}

.MultiSelect-input::placeholder {
  font-style: italic;
}

.MultiSelect-dropdown {
  list-style: none;
  padding-left: 0px;
  max-height: 150px;
  overflow-y: scroll;
  position: absolute;
  width: 100%;
  max-width: 320px;
  margin-top: 10px;

  background-color: white;
  border-radius: 5px;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 0 4px rgb(0, 0, 0);
}

.MultiSelect-dropdown::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

.MultiSelect-dropdown::-webkit-scrollbar-track {
  background: transparent;
}

.MultiSelect-dropdown::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 5px;
}

.MultiSelect-dropdown::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.589);
}

.MultiSelect-dropdown.hide {
  display: none;
}

.MultiSelect-dropdown > li {
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin: 1px;
}

.MultiSelect-dropdown > li.selected {
  background-color: black;
  color: white;
}
