.Sidebar-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: rgb(231, 231, 231);
  width: 300px;
  height: 100vh;
  position: fixed;

  will-change: transform;
  -webkit-transform: translateX(-275px);
  transform: translateX(-275px);
  transition: transform 330ms, background-color 330ms;
  z-index: 100;
}

.Sidebar-panel.anchorRight {
  flex-direction: row-reverse;
  will-change: transform;
  right: 0px;
  -webkit-transform: translateX(275px);
  transform: translateX(275px);
}

.Sidebar-panel.open {
  -webkit-transform: none;
  transform: none;
  transition: transform 330ms, background-color 330ms;
  background-color: white;
}

.Sidebar-panel-overlay {
  display: none;
  background-color: black;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 275px;
  z-index: -1;

  opacity: 0.3;
	animation-name: fadeInOverlay;
	animation-iteration-count: 1;
	animation-timing-function: linear;
	animation-duration: 330ms;
}

.Sidebar-panel-overlay.anchorRight {
  left: -100vw;
}

@keyframes fadeInOverlay {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 0.3;
	}
}

.Sidebar-panel-overlay.open {
  display: block;
}

.Sidebar-panel-items {
  display: flex;
  flex-direction: vertical;
  flex-grow: 1;
  flex-basis: 275px;
}

.Sidebar-panel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 25px;
  background-color: rgb(231, 231, 231);
  transition: background-color 330ms;
}

.Sidebar-panel-button.open {
  background-color: white;
  transition: background-color 330ms;
}

.Sidebar-panel-button:hover {
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}

.Sidebar-panel-button.open:hover {
  background-color: rgb(231, 231, 231);
}

.Sidebar-panel-button-arrow {
  transform: none;
  -webkit-transform: none;
  transition: transform 330ms;
}

.Sidebar-panel-button-arrow.open {
  will-change: transform;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transition: transform 330ms;
}
